import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { ArrowLeft, Copy, Eye, EyeSlash, Medal } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAPIAdmin, POSTAPI_ADMIN } from "../../../Auth/Index";
import { AlertError, DataGridNova, getMuiTheme } from "../../../Components/Common";
import "../../../css/Admin/customer.css";
import { useMemo } from "react";

export const Customers = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    cats: [],
    loading: true,
    errorPosition: "",
    errorText: "",
    pageSize: 10,
    showBlackListedUsers: false,
  });
  const {
    cats,
    loading,
    errorPosition,
    errorText,
    pageSize,
    showBlackListedUsers,
  } = values;

  useEffect(() => {
    GetAPIAdmin("/dashboard/users").then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          cats: res.users,
          first: false,
          loading: false,
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  }, []);

  const filteredCats = useMemo(() => {
    if (showBlackListedUsers) {
      return cats.filter((cat) => cat?.isBanned === true);
    } else {
      return cats;
    }
  }, [showBlackListedUsers, cats]);

  const columns = [
    {
      field: "name",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Contact",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "totalOrders",
      headerName: "Total Orders",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {params.value ? params.value : 0}
          </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {params.value ? `Rs${params.value.toLocaleString("ne-NP")}` : ""}
          </div>
        );
      },
    },
    {
      field: "loyaltyPoints",
      headerName: "Loyalty",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {params.value ? params.value : 0}
          </div>
        );
      },
    },
  ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No users yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const handleRowClick = (item) => {
    navigate("/customers/view", { state: item });
  };

  return (
    <div className="category_body">
      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    responsive="scrollFullHeight"
                    data={cats}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <p
        className="w3 s3 d-flex align-content-center "
        style={{ width: "985px" }}
      >
        <span className="ml-2">Customers</span>

        <div
          className="d-flex justify-content-center align-items-center gap-2 ml-auto "
          style={{
            border: "1px solid #2B2B2B",
            width: "152px",
            height: "32px",
            borderRadius: "12px",
            padding: "6px, 12px, 6px, 12px",
            color: "#2B2B2B",
            cursor: "pointer",
          }}
          onClick={() => {
            setValues((x) => ({
              ...x,
              showBlackListedUsers: !showBlackListedUsers,
            }));
          }}
        >
          {showBlackListedUsers ? <Eye size={18} /> : <EyeSlash size={18} />}
          <span
            style={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px" }}
          >
            Blacklisted Users{" "}
          </span>
        </div>
      </p>

      <div style={{ height: "65vh", width: "985px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={filteredCats}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const CustomersView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (!location.state) navigate("/customers");
  if (!location.state?._id) navigate("/customers");
  const [values, setValues] = useState({
    userId: location.state?._id,
    isBanned: location.state?.isBanned,
    data: {},
    orders: [],
    riders: [],
    loading: true,
    first: true,
    errorPosition: "",
    errorText: "",
    blackListing_loading: false,
  });
  const {
    userId,
    data,
    orders,
    isBanned,
    // riders,
    loading,
    first,
    errorPosition,
    errorText,
    blackListing_loading,
  } = values;
  useEffect(() => {
    if (first) {
      async function getUserandRiders() {
        const req1 = POSTAPI_ADMIN("/dashboard/user/", { userId: userId });
        const req2 = GetAPIAdmin("/employee/riders");
        var result = await Promise.all([req1, req2]); //Wait all api request

        var userDataAPI = result[0];
        var driverDataAPI = result[1];

        if (userDataAPI.success && driverDataAPI.success) {
          setValues((prev) => ({
            ...prev,
            data: userDataAPI.userInfo,
            orders: userDataAPI.userInfo.orders,
            riders: driverDataAPI.riders,
            first: false,
            loading: false,
          }));
        } else {
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: "Something went wrong",
            loading: false,
          }));
        }
        return await result;
      }
      getUserandRiders();
    }
    // POSTAPI_ADMIN('/dashboard/user/', { userId: userId })
    //     .then(res => {
    //         if (res.success) {
    //             setValues(prev => ({
    //                 ...prev, data: res.userInfo,
    //                 orders: res.userInfo.orders,
    //                 first: false,
    //                 loading: false
    //             }))
    //         }
    //         else if (res.error) setValues(prev => ({
    //             ...prev,
    //             errorPosition: 'apiErr',
    //             first: false,
    //             errorText: res.error, loading: false
    //         }))
    //     })
  }, [first, userId]);

  const copyGoogleLocation = (e, loc) => {
    e.stopPropagation();
    if (loc && loc.latitude && loc.longitude) {
      var lat = loc.latitude;
      var long = loc.longitude;
      var googleLocation = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      navigator.clipboard.writeText(googleLocation);
      setValues((x) => ({
        ...x,
        errorPosition: "success",
        errorText: "Copied location",
      }));
    }
  };
  // const columns = [
  //     {
  //         name: "orderId",
  //         label: "order ID",
  //         options: {
  //             filter: true,
  //             sort: true,
  //         }
  //     },
  //     {
  //         name: "location",
  //         label: "Location",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "amount",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <Button onClick={e => e.stopPropagation()} className="table_dot_icon">...</Button>
  //                 )
  //             }
  //         }
  //     }
  // ]
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No orders yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "No.",
      width: 120,
      renderCell: (params) => {
        return "#" + params.value;
      },
    },
    {
      field: "delivery",
      headerName: "Location",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              {parseFloat(params.value.location.latitude).toFixed(4)}...
            </span>
            <span className="ml-2 mr-1">
              {parseFloat(params.value.location.longitude).toFixed(4)}...
            </span>
            <IconButton
              onClick={(e) => copyGoogleLocation(e, params.value.location)}
            >
              <Copy />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.value
              ? "Rs. " + parseFloat(params.value.total).toFixed(2)
              : ""}
          </>
        );
      },
    },
  ];

  const handleRowClick = (item) => {
    if (item) {
      //   item.riders = riders;
      //   if (item.status === ORDER_STATUS.QUEUE)
      //     navigate("/orders/draft/edit", { state: item });
      //   else navigate("/orders/created/view", { state: item });
      navigate(`/orders/draft/view/${item._id}`);
    }
  };

  const blackList = () => {
    setValues((x) => ({
      ...x,
      blackListing_loading: true,
    }));
    POSTAPI_ADMIN("/user/banUnBanUser", {
      userId: location.state?._id || "",
      phone: location.state?.phone || "",
    }).then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          isBanned: res.user?.isBanned,
          blackListing_loading: false,
        }));
        if (res.user?.isBanned) {
          setValues((x) => ({
            ...x,

            errorPosition: "success",
            errorText: "This user has been blackListed",
          }));
        }
        if (!res.user?.isBanned) {
          setValues((x) => ({
            ...x,

            errorPosition: "success",
            errorText: "This user has been removed from blackList",
          }));
        }
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
          blackListing_loading: false,
        }));
    });
  };

  return (
    <>
      {loading ? (
        <>
          <Button
            className="category_top_add"
            onClick={() => navigate("/customers")}
          >
            <ArrowLeft size={30} />
            <Skeleton variant="h1" width={200} />
          </Button>
          <div className="cus_body">
            <Skeleton variant="rectangular" width={"256px"} height={200} />

            <div className="cus_body_right">
              <Skeleton variant="h1" width={200} />
              <p />
              <Skeleton variant="rectangular" width={"100%"} height={200} />
            </div>
          </div>
        </>
      ) : (
        <>
          <Button
            className="category_top_add"
            onClick={() => navigate("/customers")}
          >
            <ArrowLeft size={30} />
            <span className="ml-2">{data?.name}</span>
          </Button>
          <div className="cus_body">
            <div className="cus_body_left" style={{ height: "fit-content" }}>
              <Avatar
                variant="rounded"
                src={data?.image}
                className="cus_image"
              />
              <Button className="cus_loyal_btn">
                <Medal color="#8943EF" />
                <span className="cus_loyal_text">LP:</span>
                <span className="cus_loyal_value ml-1">
                  {data.loyaltyPoints}XP
                </span>
              </Button>
              <p className="cus_name">{data.name}</p>
              {location.state?.phone && (
                <p className="cus_phone">{location.state?.phone}</p>
              )}
              <p className="cus_phone text-center ">{data.email}</p>

              {blackListing_loading ? (
                <>
                  {" "}
                  <div className="cus_blacklist">
                    <CircularProgress sx={{ color: "#8943EF" }} size={25} />{" "}
                  </div>
                </>
              ) : isBanned ? (
                <>
                  <div className="cus_blacklist" onClick={() => blackList()}>
                    Remove Blacklist
                  </div>
                </>
              ) : (
                <>
                  <div className="cus_blacklist" onClick={() => blackList()}>
                    Blacklist User
                  </div>
                </>
              )}
            </div>

            <div className="cus_body_right">
              <p className="cus_name mt-0">Order History</p>
              <ThemeProvider theme={getMuiTheme()}>
                {/* <MUIDataTable
                                        data={orders}
                                        columns={columns}
                                        options={options}
                                    /> */}
              </ThemeProvider>

              <div style={{ height: "60vh", width: "600px" }}>
                <DataGridNova
                  loading={loading}
                  getRowHeight={() => "auto"}
                  headerHeight={70}
                  rows={orders}
                  columns={columnsData}
                  pageSize={10}
                  rowsPerPageOptions={[5]}
                  getRowId={(row) => row._id}
                  disableSelectionOnClick
                  onCellClick={(e) => handleRowClick(e.row)}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
