import { Avatar, IconButton, InputBase, Paper } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  DELETEAPIAdmin,
  POSTAPI_CreateInfoAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
  PUTAPIAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  ErrorsComp,
  HalfRightBtnComp,
  InputComp,
  InputCompTextarea,
  InputComp_Num,
  LabelComp,
  ReactSelectDrop,
  ReactSelectDropMulti,
  RedSwitch,
  SelectImage,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import { ValidateSingleItem } from "../../Components/Validate_Admin";
import ImageUploadWithPreview from "../../Components/image-upload-with-preview/index";
import "../../css/Admin/item.css";

export const SingleItem = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    items: [],
    searchedItems: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    items,
    searchedItems,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            items: res.allItems,
            searchedItems: res.allItems,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: 'image',
  //         label: 'Image',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Item",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 var index = tableMeta.rowIndex
  //                 return (
  //                     <div className="d-flex align-items-center">
  //                         <Avatar style={{ borderRadius: '12px' }}
  //                             src={tableMeta.currentTableData[index].data[1]} />
  //                         <span className="ml-3">{value}</span>
  //                     </div>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "categoryName",
  //         label: "Category",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "price",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No item yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const handleChangeAvailiability = (item) => {
    const body = {
      itemId : item._id
    }
    const updatedItems = searchedItems.map(x =>
      x._id === item._id ? { ...x, isAvailableOnline: !x.isAvailableOnline } : x
    );
    setValues(x=>({...x, searchedItems: updatedItems}))
    PUTAPIAdmin("/item/toggle-display-status", body).then((res) => {
        if (res.success) {
        } else if (res.error) {
          const updatedItems = searchedItems.map(x =>
            x._id === item._id ? { ...x, isAvailableOnline: !x.isAvailableOnline } : x
          );
          setValues(x=>({...x, searchedItems: updatedItems}))
        }
      });
  }

  const columnsData = [
    {
      field: "name",
      headerName: "Item",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 150,
      renderCell: (params) => {
        return <span className="overflow-hidden">{params.value}</span>;
      },
    },
    {
      field: "price",
      headerName: "Amount",
      width: 120,
      renderCell: (params) => {
        return <span>Rs. {params.value}</span>;
      },
    },
    {
      field: "abc",
      headerName: "Available",
      width: 95,
      renderCell: (params) => {
        const isAvailable = params.row.isAvailableOnline
        return  <div className="d-flex justify-content-between mt-3">
        <RedSwitch
          checked={isAvailable}
          onChange={(e) => {
            handleChangeAvailiability(params.row)
          }
          }
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      },
    },
    {
      field: "status",
      headerName: "",
      description: "This column is sortable",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];
  const EditFn = () => {
    var item = items.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/items/single/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = items.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/item/remove", { itemId: edClicked }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            errorPosition: "success",
            errorText: `Item ${item.name} deleted`,
            items: res.items,
          }));
        } else if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleRowClick = (rowData, rowMeta) => {
    if(!rowMeta) return
    var item = items[rowMeta.dataIndex];
    navigate("/items/single/edit", { state: item });
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    const results = searchMenuItems(searchText, items);
    setValues((x) => ({ ...x, searchedItems: results }));
  };

  return (
    <div className="category_body">
      {TopAddComp("Single Items", "/items/single/add", "Add Item")}
      <Paper
        component="form"
        sx={{
          p: "0px 16px",
          display: "flex",
          border: "1px solid #E9ECFF",
          borderRadius: "25px",
          alignItems: "center",
          width: "100%",
          boxShadow: "none",
          height: "40px",
          my: "10px",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearch}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <MagnifyingGlass />
        </IconButton>
      </Paper>

      <div style={{ height: "65vh", width: "800px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={searchedItems}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

function searchMenuItems(searchQuery, data) {
  if (!searchQuery) {
    return data;
  }

  searchQuery = searchQuery.toLowerCase();

  const filteredData = data.filter((item) =>
    item.name?.toLowerCase()?.includes(searchQuery),
  );

  return filteredData;
}

export const AddSingleItem = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    category: "",
    brand: "",
    division: "",
    image: null,
    thumbnailImage: null,
    price: "",
    dineInPrice: "",
    estimatedTime: "",
    description: "",
    modifiers: [],
    errorPosition: "",
    errorText: "",
    loading: false,
    first: true,
    allBrands: [],
    allDivisions: [],
    allCategory: [],
    allModifiers: [],
    hasSpiceLevel: false,
    isAvailableOnline : true,
    isAvailableDineIn : true,
    isVeg: false,
  });
  const {
    name,
    category,
    brand,
    division,
    image,
    thumbnailImage,
    price,
    dineInPrice,
    estimatedTime,
    description,
    modifiers,
    errorPosition,
    errorText,
    loading,
    first,
    allBrands,
    allDivisions,
    allCategory,
    allModifiers,
    hasSpiceLevel,
    isAvailableOnline,
    isAvailableDineIn,
    isVeg,
  } = values;

  useEffect(() => {
    if (first) {
      if (first) {
        POSTAPI_CreateInfoAdmin().then((res) => {
          if (res.success) {
            setValues((prev) => ({
              ...prev,
              allBrands: res.brands,
              allCategory: res.categories,
              allModifiers: res.modifiers,
              first: false,
            }));
          }
        });
      }
    }
  }, [first]);

  const AddItem = async () => {
    if (loading) return;
    var validated = await ValidateSingleItem(
      name,
      category,
      brand,
      division,
      image,
      price,
      estimatedTime,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var mods = [];
      modifiers.map((each) => mods.push(each._id));
      var formData = new FormData();
      formData.append("name", name);
      formData.append("brandId", brand);
      formData.append("divisionId", division);
      formData.append("categoryId", category);
      formData.append("image", image);
      formData.append(
        "thumbnailImage",
        dataURLtoFile(thumbnailImage, "thumbnail"),
      );
      formData.append("price", price);
      formData.append("isAvailableOnline", isAvailableOnline);
      formData.append("isAvailableDineIn", isAvailableDineIn);
      formData.append("dineInPrice", dineInPrice);
      formData.append("estimatedTime", estimatedTime);
      formData.append("description", description);
      formData.append("modifiers", JSON.stringify(mods));
      formData.append("hasSpiceLevel", hasSpiceLevel);
      formData.append("isVeg", isVeg);
      POSTAPI_formAdmin("/item/create", formData).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            loading: false,
            errorPosition: "success",
            errorText: "Added",
          }));
          navigate("/items/single");
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const [thumbModal, setThumbModal] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);

  return (
    <>
      {TopBackComp("Add Item", "/items/single")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Item Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            {LabelComp("Category", "category", errorPosition, errorText)}
            {ReactSelectDrop(category, "category", setValues, allCategory)}

            {LabelComp("Brand", "brand", errorPosition, errorText)}
            <Select
              maxMenuHeight={"200px"}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              options={allBrands.map((item) =>
                item._id ? { label: item.name, value: item._id } : item,
              )}
              value={allBrands
                .filter((option) => option._id === brand)
                .map((item) => ({ label: item.name, value: item._id }))}
              name="brand"
              onChange={(e) => {
                setValues((mainValues) => ({
                  ...mainValues,
                  brand: e.value,
                  allDivisions: allBrands.find((x) => x._id === e.value)
                    ? allBrands.find((x) => x._id === e.value).divisions
                    : [],
                }));
              }}
            />

            {LabelComp("Division", "division", errorPosition, errorText)}
            <Select
              isDisabled={brand === ""}
              maxMenuHeight={"200px"}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              options={allDivisions.map((item) =>
                item.divisionId
                  ? { label: item.name, value: item.divisionId }
                  : item,
              )}
              value={allDivisions
                .filter((option) => option.divisionId === division)
                .map((item) => ({ label: item.name, value: item.divisionId }))}
              name="division"
              onChange={(e) =>
                setValues((mainValues) => ({
                  ...mainValues,
                  division: e.value,
                }))
              }
            />

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Available online</span>
              <RedSwitch
                checked={isAvailableOnline}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    isAvailableOnline: !isAvailableOnline,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
           
            <div className="d-flex justify-content-between mt-3">
              <span>Available on dine in</span>
              <RedSwitch
                checked={isAvailableDineIn}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    isAvailableDineIn: !isAvailableDineIn,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>

          <div className="single_item_left">
            {LabelComp("Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              price,
              "price",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp("Dine In Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              dineInPrice,
              "dineInPrice",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex mb-1 align-items-center">
              <span className="mr-1 mb-1">
                Image
                <span className="s1"> (should be 600*400)</span>
              </span>
              {ErrorsComp("image", errorPosition, errorText)}
            </div>
            {SelectImage(image, "image", setValues)}

            <div className="relative">
              <div className="z-100">
                <ImageUploadWithPreview
                  thumbModal={thumbModal}
                  setThumbModal={setThumbModal}
                  label="Upload Thumbnail"
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  setFieldValue={setValues}
                  setValues={setValues}
                />
              </div>
            </div>

            {LabelComp(
              "Preparation time (minutes)",
              "estimatedTime",
              errorPosition,
              errorText,
            )}
            {InputComp_Num(
              estimatedTime,
              "estimatedTime",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp("Modifiers", "modifiers", errorPosition, errorText)}
            {ReactSelectDropMulti(
              modifiers,
              "modifiers",
              setValues,
              allModifiers,
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Spice Level</span>
              <RedSwitch
                checked={hasSpiceLevel}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    hasSpiceLevel: !hasSpiceLevel,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>

            <div className="d-flex justify-content-between mt-3">
              <span>vegetarian</span>
              <RedSwitch
                checked={isVeg}
                onChange={() =>
                  setValues((prev) => ({ ...prev, isVeg: !isVeg }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>

        {HalfRightBtnComp("Add Item", AddItem, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditSingleItem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) navigate("/items/single");

  const [values, setValues] = useState({
    _id: location.state ? location.state._id : "",
    name: location.state ? location.state.name : "",
    brand: location.state ? location.state.brandId : "",
    division: location.state ? location.state.divisionId : "",
    category: location.state ? location.state.categoryId : "",
    image: location.state ? location.state.image : "",
    thumbnailImage: location.state ? location.state.thumbnailImage : "",
    price: location.state ? location.state.price : "",
    dineInPrice: location.state ? location.state.dineInPrice : "",
    estimatedTime: location.state ? location.state.estimatedTime : "",
    description: location.state ? location.state.description : "",
    modifiers: location.state
      ? location.state.modifiers.map((each) => ({
          _id: each.modifierId,
          name: each.name,
        }))
      : "",
    errorPosition: "",
    errorText: "",
    loading: false,
    first: true,
    allBrands: [],
    allDivisions: [],
    allCategory: [],
    allModifiers: [],
    hasSpiceLevel: location.state ? location.state.hasSpiceLevel : "",
    isAvailableOnline: location.state.isAvailableOnline ?? false,
    isAvailableDineIn: location.state.isAvailableDineIn ?? false,
    isVeg: location.state?.isVeg ?? false,
  });
  const {
    name,
    brand,
    division,
    category,
    image,
    thumbnailImage,
    price,
    dineInPrice,
    estimatedTime,
    description,
    modifiers,
    errorPosition,
    errorText,
    loading,
    first,
    allBrands,
    allDivisions,
    allCategory,
    allModifiers,
    hasSpiceLevel,
    isAvailableOnline,
    isAvailableDineIn,
    isVeg,
  } = values;

  useEffect(() => {
    if (first) {
      if (first) {
        POSTAPI_CreateInfoAdmin().then((res) => {
          if (res.success) {
            var divs = res.brands.find((x) => x._id === brand)
              ? res.brands.find((x) => x._id === brand).divisions
              : [];
            setValues((prev) => ({
              ...prev,
              allBrands: res.brands,
              allDivisions: divs,
              allCategory: res.categories,
              allModifiers: res.modifiers,
              first: false,
            }));
          }
        });
      }
    }
  }, [first, brand]);

  const UpdateItem = async () => {
    if (loading) return;
    var validated = await ValidateSingleItem(
      name,
      category,
      brand,
      division,
      image,
      price,
      estimatedTime,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var mods = [];
      modifiers.map((each) => mods.push(each._id));
      var formData = new FormData();
      formData.append("itemId", location.state._id);
      formData.append("name", name);
      formData.append("brandId", brand);
      formData.append("divisionId", division);
      formData.append("categoryId", category);
      formData.append("image", image);
      formData.append(
        "thumbnailImage",
        dataURLtoFile(thumbnailImage, "thumbnail"),
      );
      formData.append("price", price);
      formData.append("dineInPrice", dineInPrice);
      formData.append("isAvailableOnline", isAvailableOnline);
      formData.append("isAvailableDineIn", isAvailableDineIn);
      formData.append("estimatedTime", estimatedTime);
      formData.append("description", description);
      formData.append("modifiers", JSON.stringify(mods));
      formData.append("hasSpiceLevel", hasSpiceLevel);
      formData.append("isVeg", isVeg);
      PUT_API_formAdmin("/item/edit", formData).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            loading: false,
            errorPosition: "success",
            errorText: "Updated",
          }));
        else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };
  const [thumbModal, setThumbModal] = useState(true);
  const [previewImage, setPreviewImage] = useState(
    location.state ? location.state?.thumbnailImage : "",
  );

  return (
    <>
      {TopBackComp("Edit Item", "/items/single")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Item Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            {LabelComp("Brand", "brand", errorPosition, errorText)}
            <Select
              maxMenuHeight={"200px"}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              options={allBrands.map((item) =>
                item._id ? { label: item.name, value: item._id } : item,
              )}
              value={allBrands
                .filter((option) => option._id === brand)
                .map((item) => ({ label: item.name, value: item._id }))}
              name="brand"
              onChange={(e) => {
                setValues((mainValues) => ({
                  ...mainValues,
                  brand: e.value,
                  allDivisions: allBrands.find((x) => x._id === e.value)
                    ? allBrands.find((x) => x._id === e.value).divisions
                    : [],
                }));
              }}
            />

            {LabelComp("Division", "division", errorPosition, errorText)}
            <Select
              isDisabled={brand === ""}
              maxMenuHeight={"200px"}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              options={allDivisions.map((item) =>
                item.divisionId
                  ? { label: item.name, value: item.divisionId }
                  : item,
              )}
              value={allDivisions
                .filter((option) => option.divisionId === division)
                .map((item) => ({ label: item.name, value: item.divisionId }))}
              name="division"
              onChange={(e) =>
                setValues((mainValues) => ({
                  ...mainValues,
                  division: e.value,
                }))
              }
            />

            {LabelComp("Category", "category", errorPosition, errorText)}
            {ReactSelectDrop(category, "category", setValues, allCategory)}

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Available online</span>
              <RedSwitch
                checked={isAvailableOnline}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    isAvailableOnline: !isAvailableOnline,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
           
            <div className="d-flex justify-content-between mt-3">
              <span>Available on dine in</span>
              <RedSwitch
                checked={isAvailableDineIn}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    isAvailableDineIn: !isAvailableDineIn,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div className="single_item_left">
            {LabelComp("Online Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              price,
              "price",
              setValues,
              "add_category_input_full",
            )}
           
            {LabelComp("Dine In Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              dineInPrice,
              "dineInPrice",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex mb-1 align-items-center">
              <span className="mr-1 mb-1">
                Image
                <span className="s1"> (should be 600*400)</span>
              </span>
              {ErrorsComp("image", errorPosition, errorText)}
            </div>
            {SelectImage(image, "image", setValues)}

            <div className="relative">
              <div className="z-100">
                <ImageUploadWithPreview
                  thumbModal={thumbModal}
                  setThumbModal={setThumbModal}
                  label="Upload Thumbnail"
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  setFieldValue={setValues}
                  setValues={setValues}
                />
              </div>
            </div>

            {LabelComp(
              "Preparation time (minutes)",
              "estimatedTime",
              errorPosition,
              errorText,
            )}
            {InputComp_Num(
              estimatedTime,
              "estimatedTime",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp("Modifiers", "modifiers", errorPosition, errorText)}
            {ReactSelectDropMulti(
              modifiers,
              "modifiers",
              setValues,
              allModifiers,
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Spice Level</span>
              <RedSwitch
                checked={hasSpiceLevel}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    hasSpiceLevel: !hasSpiceLevel,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>

            <div className="d-flex justify-content-between mt-3">
              <span>Vegetarian</span>
              <RedSwitch
                checked={isVeg}
                onChange={() =>
                  setValues((prev) => ({ ...prev, isVeg: !isVeg }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>

        {HalfRightBtnComp("Update Item", UpdateItem, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

function dataURLtoFile(dataurl, filename) {
  if (!dataurl) return null;
  if (dataurl.includes("amazonaws")) return dataurl;
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
