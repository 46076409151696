import { IconButton } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import { useLocation, useNavigate } from "react-router-dom";
import { isTextEmpty } from "../Validate_Admin";

export const HeaderTitle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, url } = fetchFixedTitles(location);

  return (
    <>
      {!isTextEmpty(title) && (
        <div className="c3 s-20 w3 d-flex gap-2 mr-auto align-items-center" style={{ marginRight:'auto' }}>
          {!isTextEmpty(url) && (
            <IconButton
              onClick={(e) => {
                navigate(url);
              }}
            >
              <ArrowLeft />
            </IconButton>
          )}
          {title}
        </div>
      )}
    </>
  );
};

const fetchFixedTitles = (location) => {
  let title = "";
  let url = "";
  switch (location.pathname) {
    case "/dashboard":
      title = "Dashboard";
      break;
    case "/dashboard/cus":
      title = "Top Customer";
      url = "/dashboard";
      break;
    case "/customers/dash":
      title = "Customer Dashboard";
      url = "/customers/dash";
      break;
    case "/dashboard/items":
      title = "Top Selling Items";
      url = "/dashboard";
      break;
    case "/stats/order-stats":
      title = "Order Stats";
      break;
    case "/stats/cooking-stats":
      title = "Cooking stats";
      break;
    case "/stats/rider-stats":
      title = "Rider stats";
      break;
    default:
      break;
  }
  return { title, url };
};
