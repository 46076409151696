import {
  BookOpen,
  ChartLineUp,
  CirclesThreePlus,
  DeviceMobileCamera,
  Fire,
  ForkKnife,
  Gear,
  GoogleChromeLogo,
  IdentificationCard,
  PaintBrushBroad,
  ShoppingBag,
  SquaresFour,
  Storefront,
  Users,
} from "@phosphor-icons/react";
import { ENTITY_TYPE } from "./staticData";

export const drawerData = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <SquaresFour size={28} />,
    permission : ENTITY_TYPE.DASHBOARD
  },
  {
    name: "Stats",
    path: "/stats",
    icon: <ChartLineUp size={28} />,
    sub: [
      {
        name: "Cooking Stats",
        path: "/stats/cooking-stats",
        permission : ENTITY_TYPE.COOKING_STATS
      },
      {
        name: "Order Stats",
        path: "/stats/order-stats",
        permission : ENTITY_TYPE.ORDER_STATS
      },
      {
        name: "Rider Stats",
        path: "/stats/rider-stats",
        permission : ENTITY_TYPE.RIDER_STATS
      },
    ],
  },
  {
    name: "Marketplace",
    path: "/marketplace",
    icon: <GoogleChromeLogo size={28} />,
    permission : ENTITY_TYPE.MARKETPLACE
  },
  {
    name: "Orders",
    path: "/orders",
    icon: <ShoppingBag size={28} />,
    sub: [
      {
        name: "Created Orders",
        path: "/orders/created",
        permission : ENTITY_TYPE.ORDER
      },
      {
        name: "Online Orders",
        path: "/orders/onlineorders",
        permission : ENTITY_TYPE.ORDER
      },
      // {
      //   name: "Draft Orders",
      //   path: "/orders/draft",
      // },
    ],
  },
  {
    name: "Menu",
    path: "/menu",
    icon: <BookOpen size={28} />,
    sub: [
      {
        name: "Items",
        path: "/items",
        icon: <ForkKnife size={28} />,
        sub: [
          {
            name: "Single Items",
            path: "/items/single",
            permission : ENTITY_TYPE.ITEM
          },
          {
            name: "Combo Meals",
            path: "/items/combo",
            permission : ENTITY_TYPE.COMBO
          },
        ],
      },
      {
        name: "Modifiers",
        path: "/modifiers",
        icon: <PaintBrushBroad size={28} />,
        permission : ENTITY_TYPE.MODIFIER
      },
      {
        name: "Categories",
        path: "/categories",
        icon: <CirclesThreePlus size={28} />,
        permission : ENTITY_TYPE.CATEGORY
      },
    ],
  },
  {
    name: "Brands",
    path: "/brands",
    icon: <Storefront size={28} />,
    permission : ENTITY_TYPE.BRAND
  },
  {
    name: "App",
    path: "/app",
    icon: <DeviceMobileCamera size={28} />,
    // permission : ENTITY_TYPE.A
  },
  {
    name: "Offers",
    path: "/offers",
    icon: <Fire size={28} />,
    permission : ENTITY_TYPE.OFFER
  },
  // {
  //   name: "Locations",
  //   path: "/locations",
  //   icon: <MapPin size={28} />,
  // },
  {
    name: "Customers",
    path: "/customers",
    icon: <Users size={28} />,
    sub: [
      {
        name: "Customers",
        path: "/customers/list",
        permission : ENTITY_TYPE.DASHBOARD
      },
      {
        name: "Dashboard",
        path: "/customers/dash",
        permission : ENTITY_TYPE.DASHBOARD
      },
      {
        name: "Retention",
        path: "/customers/retention",
        permission: ENTITY_TYPE.DASHBOARD
      },
    ],
  },
  // {
  //   name: "Shifts",
  //   path: "/shifts",
  //   icon: <ClockClockwise size={28} />,
  // },
  // {
  //   name: "Reviews",
  //   path: "/reviews",
  //   icon: <Star size={28} />,
  // },
  {
    name: "HR",
    path: "/hr",
    icon: <IdentificationCard size={28} />,
    sub: [
      {
        name: "Designations",
        path: "/hr/designations",
        permission : ENTITY_TYPE.DESIGNATION
      },
      {
        name: "Employees",
        path: "/hr/employees",
        permission : ENTITY_TYPE.EMPLOYEE
      },
      {
        name: "Attendance",
        path: "/hr/attendance",
        // permission : ENTITY_TYPE.A
      },
    ],
  },
  // {
  //   name: "Inventory",
  //   path: "/inventory",
  //   icon: <Package size={28} />,
  // },
  {
    name: "Settings",
    path: "/settings",
    icon: <Gear size={28} />,
  },
];
