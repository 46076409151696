import {
    Grid,
    Paper,
    styled
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { GetAPIAdmin } from "../../../Auth/Index";
import { AlertError, CircularProgressComp } from "../../../Components/Common";
import "../../../css/Admin/admin_dashboard.css";

const Item = styled(Paper)(({ theme }) => ({
backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
...theme.typography.body2,
padding: theme.spacing(1),
textAlign: "center",
color: theme.palette.text.secondary,
boxShadow: "none",
alignItems: "center",
cursor: "pointer",
borderRadius: "12px",
}));

export const rangePresets = [
{
    label: "Today",
    value: [
    dayjs().subtract(1, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
{
    label: "Yesterday",
    value: [
    dayjs().subtract(2, "day").startOf("day").add(12, "hours"),
    dayjs().subtract(1, "day").startOf("day").add(4, "hours"),
    ],
},
{
    label: "This Week",
    value: [dayjs().startOf("week").add(12, "hours"), dayjs()],
},
{
    label: "Last Week",
    value: [
    dayjs().subtract(1, "week").startOf("week").add(12, "hours"),
    dayjs()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
},
{
    label: "This Month",
    value: [
    dayjs().startOf("month").add(12, "hours"),
    dayjs().endOf("month").add(1, "day").startOf("day").add(4, "hours"),
    ],
},
{
    label: "Last Month",
    value: [
    dayjs().subtract(1, "month").startOf("month").add(12, "hours"),
    dayjs()
        .subtract(1, "month")
        .endOf("month")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
},
{
    label: "Last 7 Days",
    value: [
    dayjs().subtract(7, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
{
    label: "Last 30 Days",
    value: [
    dayjs().subtract(30, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
];

export const rangePresetsNew = [
{
    label: "Today",
    value: [dayjs().startOf("day").add(12, "hours"), dayjs()],
},
{
    label: "Yesterday",
    value: [
    dayjs().subtract(1, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
{
    label: "This Week",
    value: [dayjs().startOf("week").add(12, "hours"), dayjs()],
},
{
    label: "Last Week",
    value: [
    dayjs().subtract(1, "week").startOf("week").add(12, "hours"),
    dayjs()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
},
{
    label: "This Month",
    value: [
    dayjs().startOf("month").add(12, "hours"),
    dayjs().endOf("month").add(1, "day").startOf("day").add(4, "hours"),
    ],
},
{
    label: "Last Month",
    value: [
    dayjs().subtract(1, "month").startOf("month").add(12, "hours"),
    dayjs()
        .subtract(1, "month")
        .endOf("month")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
},
{
    label: "Last 7 Days",
    value: [
    dayjs().subtract(7, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
{
    label: "Last 30 Days",
    value: [
    dayjs().subtract(30, "day").startOf("day").add(12, "hours"),
    dayjs().startOf("day").add(4, "hours"),
    ],
},
];

export const CusDashboard = () => {
    const [values, setValues] = useState({
    errorPosition: "",
    errorText: "",
    isLoading : true,
    data : []
    });
    const { errorPosition, errorText, isLoading, data } = values;

     useEffect(() => {
        GetAPIAdmin("/dashboard/users-stats").then((res) => {
          if (res.success) {
            setValues((prev) => ({
              ...prev,
              data: res.stats,
              isLoading: false,
            }));
          } else if (res.error)
            setValues((prev) => ({
              ...prev,
              errorPosition: "apiErr",
              errorText: res.error,
              isLoading: false,
            }));
        });
      }, []);

    if (isLoading)
    return (
        <div className="d-flex justify-content-center">
            <CircularProgressComp />
        </div>
    );
    return (
        <div className="d-flex flex-column" style={{ gap: "24px", position:'relative' }}>
        <Grid container spacing={2}>
            {data?.map((each, i) => (
            <Grid key={"ad_dash_header_" + i} item xs={12} sm={12} md={2} lg={2}>
                <Item className="d-flex p-3">
                <div className="ad_dash_top_value_div">
                    <span className="s-10 c-secondary text-uppercase w3">
                    {each.title}
                    </span>
                    <span className="s3 c3">
                    {each.amount}
                    </span>
                </div>
                </Item>
            </Grid>
            ))}
        </Grid>
        {AlertError(errorPosition, errorText, setValues)}
        </div>
    );
};
