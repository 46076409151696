import { Box, CircularProgress, IconButton, InputBase, Paper } from "@mui/material";
import { CaretDown, CaretLeft, CaretRight, Copy, MagnifyingGlass } from "@phosphor-icons/react";
import { Dropdown, Space } from "antd";
import { useEffect, useState } from "react";
import { GetAPIAdmin } from "../../../Auth/Index";
import { AlertError, DataGridNova, formatDateNext } from "../../../Components/Common";
import "../../../css/Admin/customer.css";

const retentionType = [
  {
    _id : 'active,at_risk,dormant,lost,inactive',
    name : 'All'
  },
  {
    _id : 'active',
    name : 'Active'
  },
  {
    _id : 'at_risk',
    name : 'At risk'
  },
  {
    _id : 'dormant',
    name : 'Dormant'
  },
  {
    _id : 'lost',
    name : 'Lost'
  },
  {
    _id : 'inactive',
    name : 'Inactive'
  },
]

export const Retention = () => {
  const [values, setValues] = useState({
    cus : [],
    loading: true,
    errorPosition: "",
    errorText: "",
    pageSize: 100,
    type: 'active,at_risk,dormant,lost,inactive',
    searchText : '',
    page : 1,   
    totalPages : null,
    searchLoading : false
  });
  const {
    cus,
    loading,
    errorPosition,
    errorText,
    pageSize,
    type,
    searchText,
    page,
    totalPages,
    searchLoading
  } = values;

  const query = `/dashboard/users?page=${page}&limit=${pageSize}&filterRetentionStatuses=${type}&query=${searchText}`

  useEffect(() => {
    GetAPIAdmin(query).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          cus: res.users,
          first: false,
          loading: false,
          totalPages : res.totalPages,
          searchLoading : false
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  }, [query]);

  const copyUserDetails = (e, copyText) => {
    e.stopPropagation();
    navigator.clipboard.writeText(copyText);
    setValues((x) => ({
      ...x,
      errorPosition: "success",
      errorText: "Copied user phone",
    }));
  };

  const columns = [
    {
      field: "name",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Contact",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <div>{params.value}</div>
            <IconButton
              onClick={(e) => copyUserDetails(e, params.value)}
            >
              <Copy />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "lastOrderDate",
      headerName: "Last order",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="d-flex s14 justify-content-center">
            <div>{formatDateNext(params.value)}</div>
          </div>
        );
      },
    },
    {
      field: "totalOrders",
      headerName: "Total Orders",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center">
            {params.value}
          </div>
        );
      },
    },
    {
      field: "averageOrderValue",
      headerName: "AOV (Rs.)",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {params.value ? `Rs. ${params.value.toLocaleString("en-NP")}` : ""}
          </div>
        );
      },
    },
    {
      field: "retentionStatus",
      headerName: "Retention Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {params.value ? retentionType.find(x=> x._id === params.value)?.name : null}
          </div>
        );
      },
    },
  ];

  const handleSearch = async (e) => {
    setValues((prev) => ({
        ...prev,
        searchText: e.target.value,
        searchLoading : true
      }));
  };

  const FilterByStatus = () => {
    return (
      <select
      style={{ fontSize: 14, height: 45, width: 100, marginLeft: 12 }}
        name="origin"
        id="origin"
        className=""
        value={type}
        onChange={(e) =>
          setValues(x=>({...x, type: e.target.value}))
        }
      >
        <option className="select_option" disabled value="">
          Select an option
        </option>

        {retentionType.map(({ _id, name }) => {
          return (
            <option key={_id} value={_id} className="select_option">
              {name}
            </option>
          );
        })}
      </select>
    )
  }

  const handlePrevious = () => {
    if(page - 1 === 0) {
      return
    }
    else setValues(x=>({...x, page : page - 1}))
  }
  const handleNext = () => {
    if(page + 1 > totalPages) {
      return
    }
    else setValues(x=>({...x, page : page + 1}))
  }
  const handlePageSize = (newSize) => {
    setValues(x=>({...x, pageSize : newSize}))
  }
  const items = [
    {
      label: <span onClick={e=>handlePageSize(25)}>25</span>,
      key: '25',
    },
    {
      label: <span onClick={e=>handlePageSize(50)}>50</span>,
      key: '50',
    },
    {
      label: <span onClick={e=>handlePageSize(100)}>100</span>,
      key: '100',
    },
  ];

  return (
    <div className="category_body">
      <p
        className="w3 s3 d-flex align-content-center "
        style={{ width: "985px" }}
      >
        <span className="ml-2">Customers Retention</span>
        <Paper
        component="form"
        sx={{
          p: "0px 16px",
          display: "flex",
          border: "1px solid #E9ECFF",
          borderRadius: "12px",
          alignItems: "center",
          boxShadow: "none",
          height: "45px",
          mb: "10px",
          width: "400px",
          ml: "auto",
        }}
      >
        <InputBase
          sx={{ width: "400px" }}
          placeholder="Search by phone, name, email"
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearch}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          {searchLoading ? <CircularProgress style={{ color: "#8943EF" }} size={20} /> : <MagnifyingGlass />}
        </IconButton>
      </Paper>
      {FilterByStatus()}
      </p>

      <Box width={{ md:'70vw', lg:'985px'}} 
        height={{md:'40vh', lg:'65vh'}}>
          <DataGridNova
            getRowHeight={() => "auto"}
            headerHeight={70}
            rows={cus}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            getRowId={(row) => row._id ?? Math.random()}
            disableSelectionOnClick
            onPaginationModelChange={e=> {
              setValues((x) => ({ ...x, pageSize: e.pageSize, page : e.page + 1 }))
            }}
            onCellClick={(e) => null}
            hideFooter
          />
          <div className="d-flex bg-white align-items-center" style={{padding:'16px 24px'}}>
            <div className="d-flex">
              <IconButton className="bg-sec-text rounded-5" disabled={page - 1 === 0} onClick={handlePrevious}>
                <CaretLeft className="c4" size={12}/>
              </IconButton>
              <IconButton className="bg-sec-text rounded-5 ml-3" disabled={page + 1 > totalPages} onClick={handleNext}>
                <CaretRight className="c4" size={12}/>
              </IconButton>
            </div>
            <div className="d-flex ml-5" style={{gap:'10px'}}>
              <span>Page</span>
              <span>{page}</span>
              <span>of {totalPages}</span>
            </div>
            <div className="ml-auto">
              <Dropdown menu={{ items }} trigger={['click']}>
                  <Space>
                    {pageSize}
                    <CaretDown />
                    per page
                  </Space>
              </Dropdown>
            </div>
          </div>
        </Box>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};