import { lazy, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { InvalidPage, Page429 } from "./Components/Common";
import AdminLayout from "./Pages/Admin/AdminLayout";
import { AdminLogin } from "./Pages/Admin/AdminLogin";
import { AdminReset } from "./Pages/Admin/AdminReset";
import { AdminSettings } from "./Pages/Admin/AdminSettings";
import { Attendance } from "./Pages/Admin/Attendance";
import { AddBrand, Brand, EditBrand } from "./Pages/Admin/Brand";
import { AddCategory, Category, EditCategory } from "./Pages/Admin/Category";
import {
  AddComboMeals,
  ComboMeals,
  EditComboMeals,
} from "./Pages/Admin/ComboMeals";
import { Customers, CustomersView } from "./Pages/Admin/Customer/Customers";
import { AdminDashboard } from "./Pages/Admin/Dashbaord/AdminDashboard";
import { AddEditDesignation, Designation } from "./Pages/Admin/Designation";
import {
  AddEmployees,
  EditEmployees,
  Employees,
} from "./Pages/Admin/Employees";
import { Inventory } from "./Pages/Admin/Inventory";
import { AddLocation, EditLocation, Location } from "./Pages/Admin/Locations";
import { AddModifier, EditModifier, Modifier } from "./Pages/Admin/Modifier";
import { AddOffer, EditOffer, Offer } from "./Pages/Admin/Offers";
import {
  CreateOrder,
  CreatedOrders,
  EditOrder,
  Orders,
  ViewOrder,
} from "./Pages/Admin/Orders";
import { Pay } from "./Pages/Admin/Pay";
import {
  AddSingleItem,
  EditSingleItem,
  SingleItem,
} from "./Pages/Admin/SingleItem";
import CsrLayout from "./Pages/CSR/CsrLayout";
import { CsrLogin } from "./Pages/CSR/CsrLogin";
import { KDSLogin } from "./Pages/KDS/KDSLogin";
import { KDSHome } from "./Pages/KDS/KDS_Home";
import { KDSLayout } from "./Pages/KDS/KDS_Layout";
import { KDSOrder } from "./Pages/KDS/KDS_Order";
import { MapBox } from "./Pages/MapBox";
import "./index.css";
// import { Khalti } from "./Pages/KDS/Khalti"
import AdminApp from "./Pages/Admin/AdminApp";
import { Reviews } from "./Pages/Admin/Reviews";
import { CsrDashboard } from "./Pages/CSR/CsrDashboard";
import { FilterPage } from "./Pages/Market/FilterPage";
import {
  DeliveryChargesPage,
  HelpAndSupport,
  PrivacyPolicy,
  TermsAndConditions,
  WhoWeAre,
} from "./Pages/Market/FooterPages";
import { Homepage } from "./Pages/Market/HomePage/Homepage";
// import { MarketLayout } from "./Pages/Market/MarketLayout";
import { CusDashboard } from "./Pages/Admin/Customer/CusDashboard";
import { AdminDashboardCus } from "./Pages/Admin/Dashbaord/AdminDashboardCus";
import { AdminDashboardItems } from "./Pages/Admin/Dashbaord/AdminDashboardItems";
import { Marketplace } from "./Pages/Admin/Marketplace/Marketplace";
import { CookingStats } from "./Pages/Admin/Stats/CookingStats/CookingStats";
import { OrderStats } from "./Pages/Admin/Stats/OrderStats/OrderStats";
import { RiderStats } from "./Pages/Admin/Stats/RiderStats/RiderStats";
import { RiderStatsDetails } from "./Pages/Admin/Stats/RiderStats/RiderStatsDetail";
import { Loyalty } from "./Pages/Market/Loyalty/Loyalty";
import { MyAccount, OneOrder } from "./Pages/Market/MyAccount";
import { MyCart } from "./Pages/Market/MyCart";
import { AllAdminNotifs, Notifications } from "./Pages/Market/Notifications";
import { SearchPage } from "./Pages/Market/SearchPage";
import { DeliveryPage, ThankYouPage } from "./Pages/Market/ShippingPage";
import { TestScroll } from "./Pages/Market/TestScroll";
import { ViewCombos } from "./Pages/Market/ViewCombos";
import { ViewOffers } from "./Pages/Market/ViewOffers";
import { Retention } from "./Pages/Admin/Customer/Retention";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    let target = document.getElementById("scroll_here_id");
    if (target) target.scrollIntoView();
  }, [location]);

  return <>{props.children}</>;
};

const MarketLayout = lazy(() => import("./Pages/Market/MarketLayout"));

export const AllRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {/* admin */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/reset" element={<AdminReset />} />
          <Route path="/sc" element={<TestScroll />} />

          {/* csr */}
          <Route path="/csr/login" element={<CsrLogin />} />
          <Route element={<CsrLayout />}>
            <Route path="/csr/dashboard" element={<CsrDashboard />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="/pay" element={<Pay />} />
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/dashboard/cus" element={<AdminDashboardCus />} />
            <Route path="/dashboard/items" element={<AdminDashboardItems />} />
            <Route path="stats">
              <Route path="rider-stats">
                <Route index element={<RiderStats />} />
                <Route path=":id" element={<RiderStatsDetails />} />
              </Route>
              <Route path="order-stats" element={<OrderStats />} />
              <Route path="cooking-stats" element={<CookingStats />} />
            </Route>
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/orders/created">
              <Route path="" element={<CreatedOrders />} />
              <Route path="view" element={<ViewOrder />} />
              <Route path="create" element={<CreateOrder />} />
            </Route>
            <Route path="/orders/onlineorders">
              <Route path="" element={<Orders />} />
            </Route>
            <Route path="/app" element={<AdminApp />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/orders/draft">
              {/* <Route path="" element={<DraftOrders />} /> */}
              <Route path="view/:id" element={<ViewOrder />} />
              <Route path="edit/:id" element={<EditOrder />} />
            </Route>
            <Route path="/categories">
              <Route path="" element={<Category />} />
              <Route path="add" element={<AddCategory />} />
              <Route path="edit" element={<EditCategory />} />
            </Route>
            <Route path="/modifiers">
              <Route path="" element={<Modifier />} />
              <Route path="add" element={<AddModifier />} />
              <Route path="edit" element={<EditModifier />} />
            </Route>
            <Route path="/items/single">
              <Route path="" element={<SingleItem />} />
              <Route path="add" element={<AddSingleItem />} />
              <Route path="edit" element={<EditSingleItem />} />
            </Route>
            <Route path="/items/combo">
              <Route path="" element={<ComboMeals />} />
              <Route path="add" element={<AddComboMeals />} />
              <Route path="edit" element={<EditComboMeals />} />
            </Route>
            <Route path="offers">
              <Route path="" element={<Offer />} />
              <Route path="add" element={<AddOffer />} />
              <Route path="edit" element={<EditOffer />} />
            </Route>
            <Route path="brands">
              <Route path="" element={<Brand />} />
              <Route path="add" element={<AddBrand />} />
              <Route path="edit" element={<EditBrand />} />
            </Route>
            <Route path="locations">
              <Route path="" element={<Location />} />
              <Route path="add" element={<AddLocation />} />
              <Route path="edit" element={<EditLocation />} />
            </Route>
            <Route path="customers">
              <Route path="list" element={<Customers />} />
              <Route path="view" element={<CustomersView />} />
              <Route path="dash" element={<CusDashboard />} />
              <Route path="retention" element={<Retention />} />
            </Route>
            <Route path="/hr/designations">
              <Route path="" element={<Designation />} />
              <Route path="one" element={<AddEditDesignation />} />
            </Route>
            <Route path="/hr/employees">
              <Route path="" element={<Employees />} />
              <Route path="add" element={<AddEmployees />} />
              <Route path="edit" element={<EditEmployees />} />
            </Route>
            <Route path="/hr/attendance">
              <Route path="" element={<Attendance />} />
            </Route>
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/settings" element={<AdminSettings />} />
            <Route path="/admin/notifications" element={<AllAdminNotifs />} />
          </Route>

          {/* market */}
          <Route element={<MarketLayout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/map" element={<MapBox />} />
            <Route path="/filter" element={<FilterPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/allOffers" element={<ViewOffers />} />
            <Route path="/allCombos" element={<ViewCombos />} />
            <Route path="/myAccount" element={<MyAccount />} />
            <Route path="/order/:orderId" element={<OneOrder />} />
            <Route path="/myCart" element={<MyCart />} />
            <Route path="/delivery" element={<DeliveryPage />} />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/helpAndSupport" element={<HelpAndSupport />} />
            <Route path="/delivery-charges" element={<DeliveryChargesPage />} />
            <Route path="/loyalty" element={<Loyalty />} />
          </Route>
          <Route path="/whoweare" element={<WhoWeAre />} />

          {/* kds */}
          <Route path="/kds/login" element={<KDSLogin />} />
          <Route path="/kds/reset" element={<AdminReset />} />
          <Route element={<KDSLayout />}>
            <Route path="/kds/home" element={<KDSHome />} />
            <Route path="/kds/history" element={<KDSOrder />} />
          </Route>
          <Route path="/429" element={<Page429 />} />
          <Route path="*" element={<InvalidPage />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};
